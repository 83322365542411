import tick from "./../assets/tick.png";

function Pricing() {
    const PriceCard = ({ title, notes = [], price, realprice }) => (
        <div style={{ padding: 15, border: "3px solid black", borderRadius: 20, width: 300, margin: 10 }}>
            <div style={{ fontWeight: "bold", fontSize: "1.1rem", borderBottom: "3px solid black" }}>{title}</div>
            <div style={{ margin: "25px 5px" }}>
                {notes.map((note, idx) => (
                    <div style={{ display: "flex", marginTop: 15, alignItems: "center" }} key={idx + "n"}>
                        <img src={tick} width={20} height={20} />
                        <div style={{ marginLeft: 10, fontWeight: 500 }}>{note}</div>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                <div style={{ fontSize: "1.5rem", fontWeight: "600" }}>{price}</div>
                <strike style={{ margin: 4 }}>{realprice}</strike>
            </div>
        </div>
    )

    return (
        <div className="pricing-container" >
            <span style={{ fontSize: "2rem", fontWeight: "bolder" }}>Pricing</span>
            <p style={{ fontWeight: 500, }}>Choose the tier that suits your needs</p>
            <div className="price-cards-container" >
                <PriceCard
                    title={"Free"}
                    notes={[
                        "Personalised Counselling",
                        "University Shortlisting",
                        "Application Filing",
                        "SOP/LOR Review",
                        "Scholarship Guidance",
                        "Visa Assistance",
                        "Mentorship",
                        "Apply upto 4 universities",
                        "Apply upto 2 countries",
                    ]} />
                <PriceCard
                    title={"Premium"}
                    notes={[
                        "Personalised Counselling",
                        "University Shortlisting",
                        "Application Filing",
                        "SOP/LOR Review",
                        "Scholarship Guidance",
                        "Visa Assistance",
                        "Mentorship",
                        "Apply upto 8 universities",
                        "Apply upto 4 countries",
                        "Apply to Top Univ's"
                    ]}
                    price="INR 25,000"
                    realprice="INR 45,000"
                />
            </div>

        </div >)
}

export default Pricing;