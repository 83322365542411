import React from "react";
import {
    Container,
    Row,
    Col,
    
} from "reactstrap";
import "./mission.css"
import m1 from "../assets/Mission.png"



const Mission = () =>{
    return(
        <div className="Mission" id="missionS">
            <Container>
                <Row className="missionRow" >
                    <Col sm="12" md="12" lg="4" xl="4" className="mission">
                   
                    <img className="mission-img" src={m1} alt="Mission"/>
                  
                    </Col>
                    <Col sm="12" md="12" lg="8" xl="8" className="missionContent">
                    <h1>Mission</h1>
                    <p>To become Immigration's most wholesome solution by empowering individuals to make informed choices. Anyone who aspires to immigrate, deserves to Immigreat. We want to be the bridge between the aspirants and their dreams.</p>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Mission;