import NavBar from "../Components/Navbar/Navbar";
import Contact from "../Components/Contact and footer/Contact";
import Foot from "../Components/Contact and footer/Footer";
import Study from "../Components/Study/Study";

const StudyPage = () => {
  window.scrollTo(0, 0);

  return (
    <div className="About">
      <NavBar />

      <Study />
      <Contact />
      <Foot />
    </div>
  );
};

export default StudyPage;
