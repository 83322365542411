import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { FaFacebook, FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
import "./contact.css";
import { Element } from "react-scroll";
import {
  EMAIL_REGEX,
  PHONE_REGEX,
  Alert,
  BASE_URL,
  NAME,
} from "../../helpers/utils";
import axios from "axios";
import phoneImg from "../assets/phone-1.png";
import emailImg from "../assets/email.png";

const init = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
};

const Contact = ({ phoneNumber = "+91 90420 44593" }) => {
  const [formData, setFormData] = useState({ ...init });
  const [error, setError] = useState({});

  const validateData = (data) => {
    let errorObj = {};
    if (!EMAIL_REGEX.test(data["email"])) {
      errorObj["email"] = 1;
    }
    if (!NAME.test(data["firstName"])) {
      errorObj["firstName"] = 1;
    }
    if (!PHONE_REGEX.test(data["phoneNumber"])) {
      errorObj["phoneNumber"] = 1;
    }
    return [Object.keys(errorObj).length, errorObj];
  };

  const handleSubmit = async () => {
    const [isNotValid, fieldsObj] = validateData(formData);
    setError(fieldsObj);
    if (isNotValid) {
      return;
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(`${BASE_URL}/add_response`, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          Alert(
            "success",
            "Thank you for contacting us, our team will reach out to you soon!"
          );
        } else if (res.status === 400) {
          Alert("error", res.data.status);
        } else {
          Alert("error", "Some error occured, please try again!");
        }
      })
      .catch((err) => {
        Alert("error", "Some error occured, please try again!");
      });
    setFormData({ ...init });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    const data = { ...formData };

    data[name] = value;
    error[name] = 0;
    setError(error);
    setFormData(data);
  };

  return (
    <Element name="Contact">
      <div className="contact-div" id="contactS">
        <div className="content-div">
          <h1>Wanna get in touch with us?</h1>
          <div className="social-media-icons">
            <div className="social-media-helper-text">
              It's so easy, just say <strong>Hello</strong> on any of our social
              media pages
            </div>
            <div className="icons-container">
              <div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="twitter-icon"
                  href="https://twitter.com/ImmigreatGlobal"
                >
                  <FaTwitterSquare />
                </a>
              </div>
              <div>
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="facebook-icon"
                    href="https://facebook.com/immigreatplatform/"
                  >
                    <FaFacebook />
                  </a>
                </div>
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="instagram-icon"
                    href="https://www.instagram.com/immigreat_official/?igshid=15kvoublwsakz"
                  >
                    <FaInstagramSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 20 }}>
            <div>
              <img src={phoneImg} style={{ width: 50, height: 50, opacity: 0.3, }} />
              <div style={{ color: "grey", margin: 5 }}>PHONE</div>
              <div style={{ color: "grey" }}>{phoneNumber}</div>
            </div>
            <div>
              <img src={emailImg} style={{ width: 50, height: 50, opacity: 0.3 }} />
              <div style={{ color: "grey", margin: 5 }}>EMAIL</div>
              <div style={{ color: "grey" }}>info@immigreat.global</div>
            </div>
          </div> */}
          {/* <div className="form-fields-container">
            <div className="form-helper-text">
              Or just fill this form and we'll get in touch with you
            </div>
            <div className="form-fields-1">
              <FormGroup className="fields-1">
                <Input
                  type="text"
                  name="firstName"
                  className={`form-control ${error["firstName"] ? "error-input" : ""}`}
                  value={formData.firstName}
                  onChange={handleInput}
                  placeholder="First Name"
                />
              </FormGroup>
              <FormGroup className="fields-1">
                <Input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInput}
                  placeholder="Last Name"
                />
              </FormGroup>
              <FormGroup className="fields-1">
                <Input
                  type="email"
                  name="email"
                  className={`form-control ${error["email"] ? "error-input" : ""}`}
                  value={formData.email}
                  onChange={handleInput}
                  placeholder="Email"
                />
              </FormGroup>
            </div>
            <div className="form-fields-1">
              <FormGroup className="message-field">
                <Input
                  type="textarea"
                  name="message"
                  rows="3"
                  placeholder="Message"
                  style={{ resize: "none" }}
                  value={formData.message}
                  onChange={handleInput}
                />
              </FormGroup>
              <div className="fields-1">
                <FormGroup>
                  <Input
                    type="text"
                    name="phoneNumber"
                    className={`form-control ${error["phoneNumber"] ? "error-input" : ""}`}
                    value={formData.phoneNumber}
                    onChange={handleInput}
                    placeholder="Phone number"
                  />
                </FormGroup>
                <div className="contact-submit-btn">
                  <button
                    type="button"
                    className="submit-btn"
                    onClick={handleSubmit}
                  >
                    Submit{" "}
                    <span className="arrow-video submit-arrow">
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          {/* <Container>
        <Row style={{ color: "white" }} className="contact-row">
          <Col className="contact-col" sm="6" md="6" lg="3" xl="3">
            <NavLink
              href="https://m.facebook.com/immigreatplatform/"
              target="_blank"
              style={{ color: "#01021d" }}
            >
              <AiFillFacebook
                size="70"
                value={{ color: "white" }}
                style={{ cursor: "pointer" }}
              />
            </NavLink>
          </Col>
          <Col className="contact-col" sm="6" md="6" lg="3" xl="3">
            <NavLink
              href="https://twitter.com/ImmigreatGlobal"
              target="_blank"
              style={{ color: "#01021d" }}
            >
              <AiFillTwitterCircle
                size="70"
                value={{ color: "white" }}
                style={{ cursor: "pointer" }}
              />
            </NavLink>
          </Col>
          <Col className="contact-col" sm="6" md="6" lg="3" xl="3">
            <NavLink href="" target="_blank" style={{ color: "#01021d" }}>
              <SiGmail
                size="70"
                value={{ color: "white" }}
                style={{ cursor: "pointer" }}
              />
            </NavLink>
          </Col>
          <Col className="contact-col" sm="6" md="6" lg="3" xl="3">
            <NavLink
              href="https://www.instagram.com/immigreat_official/?igshid=15kvoublwsakz"
              target="_blank"
              style={{ color: "#01021d" }}
            >
              <AiOutlineInstagram
                size="70"
                value={{ color: "white" }}
                style={{ cursor: "pointer" }}
              />
            </NavLink>
          </Col>
        </Row>
      </Container> */}
        </div>
      </div>
    </Element>
  );
};

export default Contact;
