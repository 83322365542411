import NavBar from "../Components/Navbar/Navbar";
import Foot from "../Components/Contact and footer/Footer";
import AppFeatures from "../Components/AppFeatures/AppFeatures";

const AppFeaturesPage = () => {
  window.scrollTo(0, 0);

  return (
    <div className="About">
      <NavBar background={true} />

      <AppFeatures />
      <Foot />
    </div>
  );
};

export default AppFeaturesPage;
