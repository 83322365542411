import React from "react";

import MessengerCustomerChat from "react-messenger-customer-chat";

const Messenger = () => {
  return (
    <div>
      <MessengerCustomerChat pageId="105361221008475" appId="256701822478328" />
    </div>
  );
};

export default Messenger;
