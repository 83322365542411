import React, { Component } from "react";
import ReactCardCarousel from "react-card-carousel";
import insta1 from "../assets/insta1.jpg";
import insta2 from "../assets/insta2.jpg";
import insta3 from "../assets/insta3.jpg";
import insta4 from "../assets/insta4.jpg";
import insta5 from "../assets/insta5.jpg";
import insta6 from "../assets/insta6.jpg";
import insta7 from "../assets/insta7.jpg";
import "./Media.css";

class MyCarousel extends Component {
  render() {
    return (
      <>
        <div className="media-content">
          <h2 className="media-header">Our Media</h2>
          <div className="card-container">
            <ReactCardCarousel autoplay={true} autoplay_speed={2500}>
              <div
                style={{
                  backgroundImage: `url('${insta1}')`,
                }}
                className="card-style"
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta2}')`,
                }}
                className="card-style"
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta3}')`,
                }}
                className="card-style"
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta4}')`,
                }}
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta5}')`,
                }}
                className="card-style"
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta6}')`,
                }}
                className="card-style"
              ></div>
              <div
                style={{
                  backgroundImage: `url('${insta7}')`,
                }}
                className="card-style"
              ></div>
            </ReactCardCarousel>
          </div>
          <div className="social-container">
            <p className="helper-text">Check out our </p>
            <div className="media-btns">
              <a
                className="btn btn-social btn-icon btn-facebook media-btn"
                href="https://facebook.com/immigreatplatform/"
              >
                <i className="fab fa-facebook" aria-hidden="true"></i>
                <span className="w-100">Facebook</span>
              </a>
              <a
                className="btn btn-social btn-icon btn-twitter media-btn"
                href="https://twitter.com/ImmigreatGlobal"
              >
                <i className="fab fa-twitter" aria-hidden="true"></i>
                <span className="w-100">Twitter</span>
              </a>
              <a
                className="btn btn-social btn-icon btn-instagram media-btn"
                href="https://www.instagram.com/immigreat_official/?igshid=15kvoublwsakz"
              >
                <i className="fab fa-instagram" aria-hidden="true"></i>
                <span className="w-100">Instagram</span>
              </a>

              <a
                className="btn btn-social btn-icon btn-linkedin media-btn"
                href="https://www.linkedin.com/company/immigreatglobal/"
              >
                <i className="fab fa-linkedin" aria-hidden="true"></i>
                <span className="w-100">LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyCarousel;
