import React, { useState, useCallback } from "react";
import "./Solutions.css";
import { Element } from "react-scroll";
import img1 from "../assets/Media.png";
import img2 from "../assets/PATHTOOLS.png";
import img3 from "../assets/solutions.png";
import img4 from "../assets/misc.png";
import img5 from "../assets/app_intro.png";
import appIntroModal from "../assets/app_intro_modal.png";
import solutionsModal from "../assets/solutions_modal.png";
import media from "../assets/Media _modal.png";
import pathtools from "../assets/PATHTOOLS_modal.png";
import misc from "../assets/misc_modal.png";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const images = [appIntroModal, solutionsModal, media, pathtools, misc];

const OurSolutions = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showImage, setShowImage] = useState([]);
  const [modalImageLoaded, setModalImageLoaded] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const toggleModal = () => {
    setIsViewerOpen(!isViewerOpen);
    setModalImageLoaded(false);
  };
  return (
    <Element name="Solutions">
      <div id="Solutions">
        <h2 className="section-header">Our App</h2>

        <div className="gallery">
          <div className="image-intro" onClick={() => openImageViewer(0)}>
            <img
              src={img5}
              className={`gallery__img ${showImage.includes(0) ? "fade-image" : ""
                }`}
              alt="App intro"
              onLoad={() => setShowImage([...showImage, 0])}
            />
          </div>
          <div className="right-imgs-block">
            <div className="img-block">
              <div className="margin-right" onClick={() => openImageViewer(3)}>
                <img
                  src={img2}
                  className={`gallery__img ${showImage.includes(3) ? "fade-image" : ""
                    }`}
                  onLoad={() => setShowImage([...showImage, 3])}
                  alt="Pathtools"
                />
              </div>
              <div onClick={() => openImageViewer(2)}>
                <img
                  src={img1}
                  className={`gallery__img ${showImage.includes(2) ? "fade-image" : ""
                    }`}
                  alt="Media"
                  onLoad={() => setShowImage([...showImage, 2])}
                />
              </div>
            </div>
            <div className="img-block margin-top">
              <div className="margin-right" onClick={() => openImageViewer(1)}>
                <img
                  src={img3}
                  className={`gallery__img ${showImage.includes(1) ? "fade-image" : ""
                    }`}
                  alt="Advice channel"
                  onLoad={() => setShowImage([...showImage, 1])}
                />
              </div>
              <div onClick={() => openImageViewer(4)}>
                <img
                  src={img4}
                  className={`gallery__img ${showImage.includes(4) ? "fade-image" : ""
                    }`}
                  onLoad={() => setShowImage([...showImage, 4])}
                  alt="Solutions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" isOpen={isViewerOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Our Solutions</ModalHeader>
        <ModalBody>
          <div
            className={`mx-auto ${currentImage === 0 || currentImage === 2 ? "modal-img" : ""
              }`}
          >
            <img
              src={images[currentImage]}
              alt="Our Solutions"
              className={`gallery__img ${modalImageLoaded ? "fade-image" : ""}`}
              onLoad={() => setModalImageLoaded(true)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Element>
  );
};

export default OurSolutions;
