import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Download.css";
import downApp from "../assets/download-app.png";
import playStore from "../assets/playstore.png";
import appStore from "../assets/appstore.png";

const DownloadSection = () => {
  return (
    <div className="downDiv" id="download">
      <Container>
        <Row className="downRow">
          <Col sm="12" md="12" lg="6" xl="6" className="downCol">
            {/* <Tilt> */}
            <img src={downApp} className="downApp" alt="Download App" />
            {/* </Tilt> */}
          </Col>
          <Col sm="12" md="12" lg="6" xl="6" className="storeCol">
            <h1>Available now</h1>
            <a href="https://play.google.com/store/apps/details?id=global.immigreat.app&hl=en_CA&gl=US">
              {" "}
              <img
                src={playStore}
                className="ps-icon"
                alt="Download App from playstore"
              />
            </a>
            <a href="https://apps.apple.com/in/app/immigreat/id1528555782">
              {" "}
              <img
                src={appStore}
                className="as-icon"
                alt="Download App from appstore"
              />{" "}
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DownloadSection;
