import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./About.css";
import what from "../assets/what.png";

const What = () => {
  return (
    <div className="Mission">
      <Container>
        <Row className="visionRow">
          <Col sm="12" md="12" lg="8" xl="8" className="visionContent">
            <h1>WHAT WE BELIEVE?</h1>

            <p>
              We believe that human mobility (migration & travel) has largely
              positively impacted & enabled us to thrive as a species, with
              civilizations being built from migration in search of resources.
              Thus it is prudent to enable this mobility even in our modern
              times.
            </p>

            <p>
              {" "}
              We believe that technology can help enable migration & travel in a
              smart, personalized way using AI & Big Data to increase efficiency
              to both individuals & the societies.
            </p>

            <p>
              {" "}
              We believe that Immigration & Travel opportunities + services
              should be more accessible & affordable for all who desire (we call
              them seekers).
            </p>

            <p>
              {" "}
              We hope to inspire people to become the best versions of
              themselves through unique experiences, new connections & valuable
              learnings which their current environment may not provide.
            </p>
          </Col>

          <Col sm="12" md="12" lg="4" xl="4" className="vison">
            <img className="wWh-img" src={what} alt="Mission" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default What;
