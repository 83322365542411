import React from "react";
import "./videoSection.css";
import ReactPlayer from "react-player";
import img1 from "../assets/video-thumbnail.jpg";
import { NavLink as RouterLink } from "react-router-dom";

const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer
    width="100%"
    url={url}
    light={img1}
    playing={isSelected}
    height="500px"
    controls={true}
  />
);

const VideoSection = () => {
  return (
    <>
      <div className="content-section widget-section ng-star-inserted">
        <div className="newui-content-wrapper">
          <div className="widget-section-header">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title mb-4">
                  <div className="text-left">The future</div>
                  <div className="bottom-text">of global mobility.</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="description">
                  <p>
                    Immigreat is on a mission to make a real difference to
                    countless aspirants & explorers around the world, helping
                    them to maximize their potential & achieve their dreams
                    through our technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="newui-content-wrapper text-right ng-star-inserted">
          <div className="cta-link">
            <RouterLink to="/about-us" className="button-link">
              {" "}
              About Us
            </RouterLink>
            <span className="arrow-video">
              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>

      <YoutubeSlide url="https://www.youtube.com/embed/d31oCXY7BNU?vq=hd720p;hd=1" />
    </>
  );
};

export default VideoSection;
