import NavBar from "../Components/Navbar/Navbar";
import DownloadSection from "../Components/Download section/Download";
import Mission from "../Components/Mission/mission";
import Vision from "../Components/Mission/vision";
import Values from "../Components/Mission/values";
import Contact from "../Components/Contact and footer/Contact";
import Foot from "../Components/Contact and footer/Footer";
import Who from "../Components/About/Who";
import What from "../Components/About/What";
import How from "../Components/About/How";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

const About = () => {
  window.scrollTo(0, 0);
  return (
    <div className="About">
      <NavBar />

      <Fade left>
        <Who />
      </Fade>
      <Fade right>
        <What />
      </Fade>
      <Fade left>
        <How />
      </Fade>

      <Fade>
        <Mission />
        <Vision />
        <Values />
      </Fade>
      <Slide bottom>
        <DownloadSection />
      </Slide>
      <Contact />
      <Foot />
    </div>
  );
};

export default About;
