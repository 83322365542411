import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./About.css";
import who from "../assets/who.png";

const Who = () => {
  return (
    <div className="Mission">
      <Container>
        <Row className="missionRow">
          <Col sm="12" md="12" lg="4" xl="4" className="mission">
            <img className="wWh-img" src={who} alt="whoimg" />
          </Col>

          <Col sm="12" md="12" lg="8" xl="8" className="missionContent">
            <h1>WHO WE ARE?</h1>

            <p>
              An information technology company, currently based in Canada,
              Singapore & India (primary operations centre). We're a vibrant
              team of current & aspiring Immigrants, who have applied for,
              visited, studied, worked & migrated to a number of countries
              including the USA, Canada, Australia, Singapore, Germany,
              Netherlands, Japan etc.{" "}
            </p>

            <p>
              We have seen & directly faced so many unique challenges in our
              journeys & have observed major deficiencies in the current system,
              highlighted in our previous post on “The Biggest Challenges
              Immigrants Face”. We're determined to use technology to make a
              real difference to countless other aspirants around the world,
              helping them to maximize their potential & achieve their dreams
              through our platform.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Who;
