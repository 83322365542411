import React from "react";
import "./Herosection.css";
import { Link } from "react-scroll";
import { NavLink as RouterLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const HeroSection = () => {
  return (
    <Carousel
      autoFocus={true}
      autoPlay={true}
      emulateTouch={true}
      infiniteLoop={true}
      useKeyboardArrows={true}
      showThumbs={false}
      stopOnHover={true}
      showStatus={false}
      swipeable={false}
    >
      <div className="carousel-imgs-block img-1">
        <div className="carousel-caption">
          <div className="row carousel-content">
            <div className="col-12">
              <div className="hero-slider-heading">Welcome to Immigreat</div>
              <div className="hero-slider-title one">Your BEST future.</div>
            </div>
            <div className="col-11">
              <div className="hero-slider-title two"> Simply Achieved. </div>
              <div className="hero-slider-description mt-4">
                <p>
                  <p>
                    The world's first data-driven global opportunity discovery
                    and smart planning platform. To enable ALL to achieve their
                    best future & life experiences
                  </p>
                </p>
              </div>
              <div>
                <RouterLink to="/about-us" className="button-link">
                  Read more{" "}
                  <span className="arrow-hero">
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-imgs-block img-2">
        <div className="carousel-caption">
          <div className="row carousel-content">
            <div className="col-12">
              <div className="hero-slider-heading">Solutions</div>
              <div className="hero-slider-title one">Personalized. </div>
            </div>
            <div className="col-11">
              <div className="hero-slider-title two"> To Empower. </div>
              <div className="hero-slider-description mt-4">
                <p>
                  <p>
                    Saving you time, money and stress by providing the most
                    relevant options, information, advice and policy updates
                    right to the phone in your pocket.
                  </p>
                </p>
              </div>
              <div className="button-link">
                <Link
                  activeClass="active"
                  to="Solutions"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={900}
                >
                  Read more{" "}
                  <span className="arrow-hero">
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-imgs-block img-3">
        <div className="carousel-caption">
          <div className="row carousel-content">
            <div className="col-12">
              <div className="hero-slider-heading">Join us</div>
              <div className="hero-slider-title one">Think Ahead.</div>
            </div>
            <div className="col-11">
              <div className="hero-slider-title two"> Stay Ahead. </div>
              <div className="hero-slider-description mt-4">
                <p>
                  <p>
                    From the big picture all-in-one solutions, to even the
                    tiniest details and facts, everything you need to explore
                    the world, like it was your own home.
                  </p>
                </p>
              </div>
              <div>
                <a
                  className="button-link"
                  href="https:web.immigreat.global/login"
                >
                  Sign Up{" "}
                  <span className="arrow-hero">
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default HeroSection;
