import NZflag3 from "../assets/studyabroad/NZflag3.png";
import USAFlag from "../assets/studyabroad/usa2.png";
import Ausflag from "../assets/studyabroad/aus2.png";
import singaporeFlag from "../assets/studyabroad/singapore2.png";
import UKFLag from "../assets/studyabroad/uk2.png";
import canadaT2 from "../assets/studyabroad/canadaT2.png";
import HONGKONG from "../assets/studyabroad/hongkong.png";
import GermanyFlag from "../assets/studyabroad/germany2.png";
import asiaFlag from "../assets/studyabroad/asia.png";
import europeFlag from "../assets/studyabroad/europe.png";

function Countries({ type = "study" }) {
    let countries = {
        pr: [
            {
                value: "CANADA",
                label: "Canada",
                code: 2,
                img: canadaT2,
                region: "America"
            },
            {
                value: "AUSTRALIA",
                label: "Australia",
                code: 3,
                img: Ausflag,
                region: "Australia"
            },
            {
                value: "SINGAPORE",
                label: "Singapore",
                code: 4,
                img: singaporeFlag,
                region: "Australia"
            },
            {
                value: "UNITED KINGDOM",
                label: "UK",
                code: 5,
                img: UKFLag,
                region: "Asia"
            },
            {
                value: "Germany",
                label: "Germany",
                code: 7,
                img: GermanyFlag,
                region: "Asia"
            },
            {
                value: "New Zealand",
                label: "New Zealand",
                code: 9,
                img: NZflag3,
                region: "Australia"
            },
            {
                value: "Hong Kong",
                code: 10,
                label: "Hong Kong",
                img: HONGKONG,
                region: "Asia"
            },
        ],
        study: [
            {
                value: "USA",
                label: "USA",
                code: 1,
                img: USAFlag,
                region: "America"
            },
            {
                value: "CANADA",
                label: "Canada",
                code: 2,
                img: canadaT2,
                region: "America"
            },
            {
                value: "AUSTRALIA",
                label: "Australia",
                code: 3,
                img: Ausflag,
                region: "Australia"
            },
            {
                value: "UNITED KINGDOM",
                label: "UK",
                code: 5,
                img: UKFLag,
                region: "Asia"
            },
            {
                value: "ASIA",
                label: "Asia",
                code: 8,
                img: asiaFlag,
                region: "Asia"
            },
            {
                value: "EUROPE",
                label: "Europe",
                code: 8,
                img: europeFlag,
                region: "Asia"
            },
            {
                value: "New Zealand",
                label: "New Zealand",
                code: 9,
                img: NZflag3,
                region: "Australia"
            },
        ]
    }

    const CountryItem = ({ label, img }) => (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", margin: 5 }}>
            <img src={img} className="country-img" />
            <div style={{ marginTop: 10, fontWeight: 500, fontSize: "0.9rem" }}>{label}</div>
        </div>
    );

    return (
        <div className="country-container" >
            <span style={{ fontSize: "2.5rem", fontWeight: "bolder" }}>Countries we operate</span>
            <div className="countries-c">
                <div style={{ display: "flex", marginBottom: 50, marginTop: 50 }}>
                    {countries[type].slice(0, 5).map((country, idx) => (
                        <CountryItem img={country.img} label={country.label} key={idx + "s"} />
                    ))}
                </div>
                <div style={{ display: "flex", marginBottom: 50, }}>
                    {countries[type].slice(5, 10).map((country, idx) => (
                        <CountryItem img={country.img} label={country.label} key={idx + "s"} />
                    ))}
                </div>
            </div>
        </div>)
}

export default Countries;