import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import "./Footer.css";
import logo from "../assets/logo.png";
import { NavLink as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";
import { SiGmail } from "react-icons/si";
import { EMAIL_REGEX, Alert, BASE_URL } from "../../helpers/utils";
import axios from "axios";

const Foot = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (!EMAIL_REGEX.test(email)) {
      Alert("error", "Please enter a valid email");
      return;
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(`${BASE_URL}/email/subscribe`, { email }, axiosConfig)
      .then((res) => {
        if (res.status === 201) {
          Alert(
            "success",
            "Thank you for signing up to stay up to date about the latest Immigreat changes and news. Stay tuned!"
          );
        } else if (res.status === 200) {
          Alert("success", res.data.status);
        } else if (res.status === 400) {
          Alert("error", res.data.status);
        } else {
          Alert("error", "Some error occured, please try again!");
        }
      })
      .catch((err) => {
        Alert("error", "Some error occured, please try again!");
      });
    setEmail("");
  };

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-row">
          <div className="first-block">
            <img alt="Immigreat Logo" className="logo" src={logo} />
            <ul className="social-icons mt-3">
              <li className="social-icons-list">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-icon linked-in"
                  href="https://www.linkedin.com/company/immigreatglobal/"
                >
                  <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-icons-list">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-icon fb"
                  href="https://facebook.com/immigreatplatform/"
                >
                  <i aria-hidden="true" className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="social-icons-list">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-icon insta"
                  href="https://www.instagram.com/immigreat_official/?igshid=15kvoublwsakz"
                >
                  <i aria-hidden="true" className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons-list">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-icon twitter"
                  href="https://twitter.com/ImmigreatGlobal"
                >
                  <i aria-hidden="true" className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="social-icons-list">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-icon gmail"
                  href="mailto:admin@immigreat.global"
                >
                  <i aria-hidden="true">
                    <SiGmail className="mb-1" />
                  </i>
                </a>
              </li>
            </ul>
          </div>
          <div className="links-block">
            <h5 className="footer-header">Quick Access</h5>
            <ul className="footer-links">
              <li>
                <a
                  href="https://web.immigreat.global/dashboard"
                  className="footer-link"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="https://web.immigreat.global/pathExp"
                  className="footer-link"
                >
                  Path Explorer
                </a>
              </li>
              <li>
                <a
                  href="https://web.immigreat.global/Media"
                  className="footer-link"
                >
                  Media
                </a>
              </li>
              {/* Removing it since advice channel is not available in web <li>
                <a href="#" className="footer-link">
                  Advice Channel
                </a>
              </li> */}
              <li>
                <RouterLink to="/about-us" className="footer-link">
                  About Us
                </RouterLink>
              </li>
              <li>
                <a
                  href="https://web.immigreat.global/store"
                  className="footer-link"
                >
                  Store
                </a>
              </li>
            </ul>
          </div>
          <div className="links-block">
            <h5 className="footer-header">Support</h5>
            <ul className="footer-links">
              {/* <li> */}
              {/* <a href="#" className="footer-link">
                  FAQ
                </a>
              </li> */}
              <li>
                <Link
                  to="contactS"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={900}
                  className="footer-link"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <a
                  href="https://web.immigreat.global/policies"
                  className="footer-link"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="footer-header">Stay up-to date</h5>
            <FormGroup>
              <input
                type="email"
                className="email-subscribe"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </FormGroup>
            <div>
              <button
                type="button"
                className="email-submit-btn"
                onClick={handleSubmit}
              >
                Submit{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="w-100">
          Copyrights by <strong>Immigreat</strong>
        </div>
        <div
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="top-btn"
        >
          Top <i aria-hidden="true" class="fas fa-chevron-up ml-2"></i>
        </div>
      </div>
    </div>
  );
};

export default Foot;
