import f1 from ".././Components/assets/feature.png";

import f2 from ".././Components/assets/secure.png";

import f3 from ".././Components/assets/one-stop.png";

import f4 from ".././Components/assets/connected.png";

import f5 from ".././Components/assets/deepanaly.png";

import f6 from ".././Components/assets/value.png";

import f7 from ".././Components/assets/efficient.png";

import { toast } from "react-toastify";

export const featureImgs = [f1, f2, f3, f4, f5, f6, f7];

export const features = [
  "SIMPLIFIED",

  "SECURE",

  "ONE STOP SOLUTION",

  "PERSONALIZED",

  "DEEP ANALYTICS",

  "VALUE FOR MONEY",

  "EFFICIENT",
];

export const EMAIL_REGEX =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^\d+$/;

export const NAME = /^[a-zA-Z]+$/;

export const BASE_URL =
  "https://console-dot-immigreat-qa.el.r.appspot.com/admin";

export const CONTACT_URL = "https://immigreat-qa.appspot.com/contactform";

//export const BASE_URL = "http://127.0.0.1:8000/admin";

export const Alert = (type, msg) => {
  toast[type](msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
