import Fade from "react-reveal/Fade";
import WhatWeDo from "./WhatWeDo";
import Countries from "../Countries/Countries";
import Steps from "./Steps";
import TryOut from "../TryOut/TryOut";
import axios from "axios";
import { Alert, CONTACT_URL } from "../../helpers/utils";
import ContactForm from "../ContactForm/ContactForm";
import prBg from "./../assets/pr/pr.png";
import "./style.css";

const PR = () => {

    const handleContactSubmit = async (formData) => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "guest@dashboard",
            },
        };
        await axios
            .post(`${CONTACT_URL}/pr_guidance`, formData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    Alert(
                        "success",
                        "Thank you for contacting us, our team will reach out to you soon!"
                    );
                } else if (res.status === 400) {
                    Alert("error", res.data.status);
                } else {
                    Alert("error", "Some error occured, please try again!");
                }
            })
            .catch((err) => {
                Alert("error", "Some error occured, please try again!");
            });
    };

    const handleClick = () => {
        window.open("https://web.immigreat.global/eligibilityChecker", '_blank', 'noopener,noreferrer');
    }

    return (
        <>
            <ContactForm isPR={true} onSubmit={handleContactSubmit} bgImg={prBg} />
            <Fade>
                <Steps />
            </Fade>
            <Fade>
                <WhatWeDo />
            </Fade>
            <Fade>
                <Countries type="pr" />
            </Fade>
            <Fade>
                <TryOut title={"Eligibility Checker"} onClick={handleClick} hideFree={true} />
            </Fade>
        </>
    );
};

export default PR;
