import tryout from "./../assets/tryout.png";

function TryOut({ hideFree = false, onClick = () => { }, title }) {
    return <div className="try-out-container" >
        <img src={tryout} style={{ height: 250, width: 250 }} />
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginLeft: 20 }}>
            <div>
                <div style={{ fontWeight: 500, fontSize: "1.5rem" }}>try out our</div>
                <span className="main-text">{title}</span>
                {hideFree ? null :
                    <div style={{ fontWeight: 500, fontSize: "1.5rem", textAlign: "end" }}>for free !</div>
                }
            </div>
            <button
                type="button" onClick={onClick} style={{ width: 170, padding: 8, marginTop: 10, borderRadius: 2, backgroundColor: "#F1AB32", fontWeight: "500", textAlign: "center", border: "none" }}>click here</button>
        </div>
    </div >
}

export default TryOut;