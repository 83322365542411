import Fade from "react-reveal/Fade";
import { FormGroup, Input } from "reactstrap";
import { FaFacebook, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { useState } from "react";
import { EMAIL_REGEX, NAME, PHONE_REGEX } from "../../helpers/utils";
const init = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    message: "",
};

function ContactForm({ onSubmit = () => { }, bgImg, isPR = false }) {
    const [formData, setFormData] = useState({ ...init });
    const [error, setError] = useState({});

    const validateData = (data) => {
        let errorObj = {};
        if (!EMAIL_REGEX.test(data["email_address"])) {
            errorObj["email_address"] = 1;
        }
        if (!NAME.test(data["first_name"])) {
            errorObj["first_name"] = 1;
        }
        if (!PHONE_REGEX.test(data["phone_number"])) {
            errorObj["phone_number"] = 1;
        }
        return [Object.keys(errorObj).length, errorObj];
    };

    const handleSubmit = async () => {
        const [isNotValid, fieldsObj] = validateData(formData);
        setError(fieldsObj);
        if (isNotValid) {
            return;
        }
        onSubmit(formData);
        setFormData({ ...init });
    };

    const handleInput = (e) => {
        const { value, name } = e.target;
        const data = { ...formData };

        data[name] = value;
        error[name] = 0;
        setError(error);
        setFormData(data);
    };

    return <div style={{ minHeight: "100vh", width: "100%", position: "relative", padding: "5%" }} >
        <img src={bgImg} width="100%" height="100%" style={{ position: "absolute", left: 0, top: 0 }} />
        <Fade right>
            <div className="contact-div-1">
                <div style={{ textAlign: "start" }}>{`stuck on your ${isPR ? "PR" : "study abroad"} journey?`}</div>
                <h2 style={{ textAlign: "start" }}>Wanna get in touch with us?</h2>
                <div style={{ margin: "20px 0px" }}>
                    <div className="form-fields-2">
                        <FormGroup className="fields-2">
                            <Input
                                type="text"
                                name="first_name"
                                className={`form-control ${error["first_name"] ? "error-input2" : ""}`}
                                value={formData.first_name}
                                onChange={handleInput}
                                placeholder="First Name"
                            />
                        </FormGroup>
                        <FormGroup className="fields-2">
                            <Input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInput}
                                placeholder="Last Name"
                            />
                        </FormGroup>
                    </div>
                    <div className="form-fields-2">
                        <FormGroup className="fields-2">
                            <Input
                                type="email"
                                name="email_address"
                                className={`form-control ${error["email_address"] ? "error-input2" : ""}`}
                                value={formData.email_address}
                                onChange={handleInput}
                                placeholder="Email"
                            />
                        </FormGroup>
                        <FormGroup className="fields-2">
                            <Input
                                type="text"
                                name="phone_number"
                                className={`form-control ${error["phone_number"] ? "error-input2" : ""}`}
                                value={formData.phone_number}
                                onChange={handleInput}
                                placeholder="Phone number"
                            />
                        </FormGroup>
                    </div>
                    <div className="form-fields-2">
                        <FormGroup className="message-field-2">
                            <Input
                                type="textarea"
                                name="message"
                                rows="3"
                                placeholder="Message"
                                style={{ resize: "none" }}
                                value={formData.message}
                                onChange={handleInput}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="contact-submit-btn-1">
                    <button
                        type="button"
                        className="submit-btn-1"
                        onClick={handleSubmit}
                    >
                        Submit{" "}
                        <span className="arrow-video submit-arrow">
                            <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </button>
                </div>
                <div className="icons-container-1">
                    <div>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="instagram-icon-1 social-btn"
                            href="https://www.instagram.com/immigreat_official/?igshid=15kvoublwsakz"
                        >
                            <FaInstagramSquare /><span>Instagram</span>
                        </a>

                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="facebook-icon-1 social-btn"
                            href="https://facebook.com/immigreatplatform/"
                        >
                            <FaFacebook /><span>Facebook</span>
                        </a>
                    </div>
                    <div>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="linkedin-icon-1 social-btn"
                            href="https://www.linkedin.com/company/immigreatglobal/"
                        >
                            <FaLinkedin /><span>LinkedIn</span>
                        </a>
                    </div>
                </div>
            </div>
        </Fade >
    </div >
}

export default ContactForm;