import NavBar from "../Components/Navbar/Navbar";
import Contact from "../Components/Contact and footer/Contact";
import Foot from "../Components/Contact and footer/Footer";
import { Component } from "react";
import { BASE_URL, Alert } from "../helpers/utils";
import axios from "axios";

class Subscribe extends Component {
  state = {
    unSubscribed: false,
    subscribed: false,
    hash: "",
    isLoading: true,
    isError: false,
  };

  handleError = (err) => {
    if (err?.response?.status === 400) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      Alert(
        "error",
        err?.response?.data?.status || "Some error occured, try again!"
      );
      setTimeout(() => this.props.history.push("/"), 2000);
    } else {
      this.setState({
        isError: true,
        isLoading: false,
      });
      Alert("error", "Some error occured, try again!");
      setTimeout(() => this.props.history.push("/"), 2000);
    }
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);
    const hash = query.get("e");
    if (hash) {
      this.setState({
        hash,
      });
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios
        .get(`${BASE_URL}/email/unsubscribe?e=${hash}`, null, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              unSubscribed: true,
              subscribed: false,
              isLoading: false,
              isError: false,
            });
          } else if (res.status === 400) {
            this.setState({
              isError: true,
              isLoading: false,
            });
            Alert("error", res.data.status);
            setTimeout(() => this.props.history.push("/"), 2000);
          } else {
            this.setState({
              isError: true,
              isLoading: false,
            });
            Alert("error", "Some error occured, try again!");
            setTimeout(() => this.props.history.push("/"), 2000);
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      this.props.history.push("/");
    }
  }

  handleSubscribe = async () => {
    this.setState({
      isLoading: true,
    });
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(
        `${BASE_URL}/email/subscribe?e=${this.state.hash}`,
        null,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            subscribed: true,
            unSubscribed: false,
            isLoading: false,
            isError: false,
          });
          setTimeout(() => this.props.history.push("/"), 5000);
        } else if (res.status === 400) {
          this.setState({
            isLoading: false,
            isError: true,
          });
          Alert("error", res.data.status);
          setTimeout(() => this.props.history.push("/"), 2000);
        } else {
          this.setState({
            isLoading: false,
            isError: true,
          });
          Alert("error", "Some error occured, try again!");
          setTimeout(() => this.props.history.push("/"), 2000);
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  };
  render() {
    return (
      <div className="Subscribe">
        <NavBar />
        <div className="subscribe-content">
          {this.state.isLoading ? (
            <>
              <p>Please wait...</p>
            </>
          ) : this.state.unSubscribed ? (
            <>
              <p>You have been unsubscribed from Immigreat.</p>
              <p className="resubscribe-content">
                Was this a mistake? Click{" "}
                <button
                  type="button"
                  onClick={this.handleSubscribe}
                  className="btn btn-link p-0 ml-1"
                >
                  here to resubscribe
                </button>
              </p>
            </>
          ) : this.state.isError ? (
            <>
              <p> Redirecting to Home... </p>
            </>
          ) : this.state.subscribed ? (
            <>
              <p>
                Thank you for signing up to stay up to date about the latest
                Immigreat changes and news. Stay tuned!
              </p>
              <p> Redirecting to Home...</p>
            </>
          ) : (
            <></>
          )}
        </div>

        <Contact />
        <Foot />
      </div>
    );
  }
}

export default Subscribe;
