import NavBar from "../Components/Navbar/Navbar";
import Contact from "../Components/Contact and footer/Contact";
import Foot from "../Components/Contact and footer/Footer";
import PR from "../Components/PR/PR";

const PRPage = () => {
  window.scrollTo(0, 0);

  return (
    <div className="About">
      <NavBar />

      <PR />
      <Contact phoneNumber="+91 93402 36870" />
      <Foot />
    </div>
  );
};

export default PRPage;
