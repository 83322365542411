import bar from "./../assets/steps/bar.png";
import s1 from "./../assets/steps/s1.png";
import s2 from "./../assets/steps/s2.png";
import s3 from "./../assets/steps/s3.png";
import s1Circle from "./../assets/steps/s1circle.png";
import s2Circle from "./../assets/steps/s2circle.png";
import s3Circle from "./../assets/steps/s3circle.png";
import step1 from "./../assets/steps/step1.png";
import step2 from "./../assets/steps/step2.png";
import step3 from "./../assets/steps/step3.png";

function Steps() {

    return (
        <div className="steps-container" >
            <div className="steps-header">
                <div className="step-note-text">why</div>
                <span className="step-main-text" >Study Abroad?</span>
            </div>
            <div className="step-container" style={{ justifyContent: "space-between" }}>
                <div className="step-circle" style={{ left: 0, }}>
                    <img src={s1Circle} style={{ position: "absolute", height: "100%", left: 0 }} />
                    <img src={s1} style={{ position: "absolute", height: "80%" }} />
                </div>
                <div className="step1-text" >Global Outlook</div>
                <img src={step1} className="step-img" />
                <img src={bar} style={{ position: "absolute", bottom: "-50%", left: "35%", width: "35%" }} />
            </div>
            <div className="step-container" >
                <img src={step2} className="step-img" />
                <div className="step-text">Personal Growth</div>
                <div className="step-circle" style={{ right: "-8%" }}>
                    <img src={s2Circle} style={{ position: "absolute", left: 0, height: "100%" }} />
                    <img src={s2} style={{ position: "absolute", height: "80%" }} />
                </div>
                <img src={bar} style={{ position: "absolute", bottom: "-40%", left: "35%", width: "35%", rotate: "130deg" }} />
            </div>
            <div className="step-container" style={{ justifyContent: "space-between" }} >
                <div className="step-circle" style={{ left: 0 }}>
                    <img src={s3Circle} style={{ position: "absolute", height: "100%", left: 0 }} />
                    <img src={s3} style={{ position: "absolute", height: "80%" }} />
                </div>
                <div className="step1-text">See the World</div>
                <img src={step3} className="step-img" />
            </div>
        </div >)
}

export default Steps;