import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { NavLink as RouterLink } from "react-router-dom";
import "./Navbar.css";
import bLogo from "../assets/logo.png";
import ham from "../assets/hamB.png";
import { Link, scroller } from "react-scroll";

const NavBar = ({ background = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [isBackground, setIsBackground] = useState(false);
  let history = useHistory();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const page = history.location.pathname.split("/").slice(-1)[0];

    if (page.includes("about-us") || page.includes("unsubscribe")) {
      setIsBackground(true);
    }
  }, []);

  const eventHandler = useCallback((event) => {
    const page = history.location.pathname.split("/");
    if (page.includes("about-us") || page.includes("unsubscribe")) {
      window.removeEventListener("scroll", eventHandler);
      return;
    }
    let scroll = window.scrollY;
    if (scroll >= window.innerHeight - 50) {
      setIsBackground(true);
    } else {
      setIsBackground(false);
    }
  }, []);

  window.addEventListener("scroll", eventHandler);
  const handleScroll = (element) => {
    if (element !== "Contact") {
      history.push("/", {
        element,
      });
    }
    scroller.scrollTo(element, {
      duration: 900,
      delay: 100,
      smooth: true,
      spy: true,
      offset: -70,
    });
  };
  return (
    <div className="navDiv">
      <Navbar
        expand="lg"
        className={`navbar ${isBackground || background ? "navbar-background" : ""}`}
        fixed="top"
      >
        <NavbarBrand className="navBarLogo">
          <RouterLink exact to="/">
            <img src={bLogo} alt="Immigreat logo" className="logo" />
            {isOpen ? null :
              <span className="brand">Immigreat</span>}
          </RouterLink>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <img src={ham} className="ham" alt="=" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="navList" navbar>
            <div className="left-menu">
              <Link
                activeClass="active"
                to="#"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                <NavItem className="navItem">
                  <RouterLink
                    exact
                    className="item nav-link active"
                    activeClassName="activeLink"
                    to="/about-us"
                  >
                    About Us
                  </RouterLink>
                </NavItem>
              </Link>
              <Link
                activeClass="active"
                to="#"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                <NavItem className="navItem">
                  <RouterLink
                    exact
                    className="item nav-link active"
                    activeClassName="activeLink"
                    to="/study-abroad"
                  >
                    Study Abroad
                  </RouterLink>
                </NavItem>
              </Link>
              <Link
                activeClass="active"
                to="#"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                <NavItem className="navItem">
                  <RouterLink
                    exact
                    className="item nav-link active"
                    activeClassName="activeLink"
                    to="/pr"
                  >
                    Get PR
                  </RouterLink>
                </NavItem>
              </Link>
              <Link
                activeClass="active"
                to="#"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                <NavItem className="navItem">
                  <RouterLink
                    exact
                    className="item nav-link active"
                    activeClassName="activeLink"
                    to="/app-features"
                  >
                    Our Solutions
                  </RouterLink>
                </NavItem>
              </Link>
              <NavItem className="navItem">
                <NavLink
                  className="item active"
                  href="https://blog.immigreat.global/"
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  Our Blog
                </NavLink>
              </NavItem>
              <Link
                className={`active navItem nav-link align-items ${active === "Contact" ? "activeLink" : ""
                  }`}
                to="#"
                onClick={() => handleScroll("Contact")}
              >
                <span>Contact Us</span>
              </Link>
            </div>
            <div className="contact-btn">
              <NavItem className="contact">
                <NavLink
                  className="loginButton"
                  href="https://web.immigreat.global/login"
                >
                  Login
                </NavLink>
              </NavItem>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
