import vision1 from "./../assets/studyabroad/vision1.png";
import vision2 from "./../assets/studyabroad/vision2.png";
import vision3 from "./../assets/studyabroad/vision3.png";
import vision4 from "./../assets/studyabroad/vision4.png";
import vision5 from "./../assets/studyabroad/vision5.png";
import vision6 from "./../assets/studyabroad/vision6.png";

function WhatWeDo() {
    const VisionItem = ({ img, title }) => (
        <div style={{ display: "flex", flex: 1, alignItems: "center", marginTop: 20 }}>
            <img src={img} style={{ width: 45, height: 45 }} />
            <div style={{ marginLeft: 20, fontWeight: "400", fontSize: "1.2rem" }}>{title}</div>
        </div>
    )

    return (
        <div className="what-we-do-container">
            <span style={{ fontSize: "2.5rem", fontWeight: "bolder", borderBottom: "8px solid #F1AB32" }}>What do we do?</span>
            <p style={{ marginTop: 35, fontSize: "1.2rem", fontWeight: "500" }}>Our vision is be the world’s most reliable immigration solution. We want to serve our customers to our best and to be prompt in our services</p>
            <div>
                <div className="vissions-container">
                    <VisionItem img={vision1} title="Personalized counselling" />
                    <VisionItem img={vision2} title={"SoP & LoR Review"} />
                </div>
                <div className="vissions-container">
                    <VisionItem img={vision3} title="University Shortlisting" />
                    <VisionItem img={vision4} title={"Scholarship Guidance"} />
                </div>
                <div className="vissions-container">
                    <VisionItem img={vision5} title="Application Filing" />
                    <VisionItem img={vision6} title={"Visa Assistance"} />
                </div>
            </div>
        </div >)
}

export default WhatWeDo;