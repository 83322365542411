import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Containers/Home";
import About from "./Containers/About";
import Study from "./Containers/Study";
import { ToastContainer } from "react-toastify";
import Subscribe from "./Containers/Subscribe";
import PRPage from "./Containers/PR";
import whatsappImg from "./Components/assets/whatsapp.png";
import ReactWhatsapp from 'react-whatsapp';
import AppFeaturesPage from "./Containers/AppFeatures";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us" component={About}></Route>
          <Route exact path="/study-abroad" component={Study}></Route>
          <Route exact path="/pr" component={PRPage}></Route>
          <Route exact path="/app-features" component={AppFeaturesPage}></Route>
          <Route exact path="/email/unsubscribe" component={Subscribe}></Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
      <ReactWhatsapp number="+91 7812898382" message="Hello" >
        <a className="whatsapp">
          <img src={whatsappImg} width="100%" height="100%" />
        </a>
      </ReactWhatsapp>
      <ToastContainer />
    </div>
  );
};

export default App;
