import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./About.css";
import who from "../assets/how.png";

const How = () => {
  return (
    <div className="Mission">
      <Container>
        <Row className="missionRow">
          <Col sm="12" md="12" lg="4" xl="4" className="mission">
            <img className="wWh-img" src={who} alt="Mission" />
          </Col>

          <Col sm="12" md="12" lg="8" xl="8" className="missionContent">
            <h1>HOW WE HELP?</h1>

            <p>
              We have built a mobile-first, data-driven technology platform,
              serving aspiring immigrants, international students & shoestring
              travellers. Our vision is to offer personalized, all-inclusive
              affordable solutions for the travel & immigration needs of
              humanity.
            </p>

            <h3>The Immigreat platform</h3>

            <p>
              1) Leverage technologies such as Big Data, ML & data
              visualizations to provide meaningful Travel & Immigration insights
              to users who have created a profile on the platform. We aim to
              match these profiles with suitable opportunities or solutions in
              their goal of tourism, study, work & permanent residence.{" "}
            </p>

            <p>
              2) Promptly notifies users of the ongoing changes in govt.
              policies, latest news, media & stories from around the world,
              relevant to their plans or journeys, including highlighting a
              policy change as a threat or opportunity for a given user.{" "}
            </p>

            <p>
              3) An easy-to-access planner, which is currently free on the
              platform, will also have premium features at a competitive price,
              once perfected to various situations. This aims at enabling
              travellers to make high impact decisions at speed.{" "}
            </p>

            <p>
              4) Provide low-cost easy access to expert knowledge, support
              planning & execution in the complicated world of Visa Regulations,
              clarifying doubts on the impact of a breaking news or govt. policy
              changes.
            </p>

            <p>
              5) Earn rewards, discounts & redeemables towards meeting their
              goals purchasing products or services on the platform, hoping to
              incentivize people to pick up a new language or finalize their
              immigration plans, and in some cases even share their reviews,
              while contributing to the community.
            </p>

            <p>
              6) Personalized Immigration planning + shopping & many more
              exciting features coming soon!
            </p>

            <p>
              If you are interested in working with or partnering with us -
              Please contact us at workwithus@immigreat.global Looking forward
              to hearing from you all!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default How;
