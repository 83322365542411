import NavBar from "../Components/Navbar/Navbar";
import HeroSection from "../Components/Herosection/Herosection";
import Contact from "../Components/Contact and footer/Contact";
import Foot from "../Components/Contact and footer/Footer";
import Features from "../Components/Features/Features";
import VideoSection from "../Components/VideoSection/videoSection";
import OurSolutions from "../Components/Solutions/Solutions";
import OurMedia from "../Components/Media/Media";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { scroller, Element } from "react-scroll";
import Messenger from "../Components/Messenger/Messenger";

const Home = () => {
  const history = useHistory();
  const [element, setElement] = useState(null);
  useEffect(() => {
    if (history?.location?.state?.element) {
      setElement(history.location.state.element);
      scroller.scrollTo(history.location.state.element, {
        duration: 900,
        delay: 100,
        smooth: true,
        spy: true,
        offset: -70,
      });
      let state = { ...history.location.state };
      delete state.element;
      history.replace({ ...history.location, state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Element name="Home">
      <div className="Home">
        <NavBar element={element} />
        <HeroSection />
        <VideoSection />
        <OurSolutions />

        <OurMedia />

        <Features />
        <Contact />

        <Foot />
        <Messenger />
      </div>
    </Element>
  );
};

export default Home;
