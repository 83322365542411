import bar from "./../assets/steps/bar.png";
import s1 from "./../assets/steps/s1.png";
import s2 from "./../assets/steps/s2.png";
import s3 from "./../assets/steps/s3.png";
import s4 from "./../assets/steps/s4.png";
import s1Circle from "./../assets/steps/s1circle.png";
import s2Circle from "./../assets/steps/s2circle.png";
import s3Circle from "./../assets/steps/s3circle.png";
import s4Circle from "./../assets/steps/s4circle.png";
import step1 from "./../assets/pr/step1.png";
import step2 from "./../assets/pr/step2.png";
import step3 from "./../assets/pr/step3.png";
import step4 from "./../assets/pr/step4.png";

function Steps() {

    return (
        <div className="steps-container" >
            <div className="steps-header">
                <div className="step-note-text">why</div>
                <span className="step-main-text" >Settle Abroad?</span>
            </div>
            <div className="step-container" style={{ justifyContent: "space-between" }}>
                <div className="step-circle" style={{ left: 0, }}>
                    <img src={s1Circle} style={{ position: "absolute", height: "100%", left: 0 }} />
                    <img src={s1} style={{ position: "absolute", height: "80%" }} />
                </div>
                <div className="step1-text" >Higher wages</div>
                <img src={step1} className="step-img" />
                <img src={bar} style={{ position: "absolute", bottom: "-50%", left: "35%", width: "35%" }} />
            </div>
            <div className="step-container" >
                <img src={step2} className="step-img" />
                <div className="step-text">Better work-life balance</div>
                <div className="step-circle" style={{ right: "-8%" }}>
                    <img src={s2Circle} style={{ position: "absolute", left: 0, height: "100%" }} />
                    <img src={s2} style={{ position: "absolute", height: "80%" }} />
                </div>
                <img src={bar} style={{ position: "absolute", bottom: "-40%", left: "35%", width: "35%", rotate: "130deg" }} />
            </div>
            <div className="step-container" style={{ justifyContent: "space-between" }} >
                <div className="step-circle" style={{ left: 0 }}>
                    <img src={s3Circle} style={{ position: "absolute", height: "100%", left: 0 }} />
                    <img src={s3} style={{ position: "absolute", height: "80%" }} />
                </div>
                <div className="step1-text">Health care and social security</div>
                <img src={step3} className="step-img" />
                <img src={bar} style={{ position: "absolute", bottom: "-50%", left: "35%", width: "35%" }} />
            </div>
            <div className="step-container" >
                <img src={step4} className="step-img" />
                <div className="step-text" >Quality of life</div>
                <div className="step-circle" style={{ right: "-4%" }}>
                    <img src={s4Circle} style={{ position: "absolute", height: "100%", left: 0 }} />
                    <img src={s4} style={{ position: "absolute", height: "45%" }} />
                </div>
            </div>
        </div >)
}

export default Steps;