import React from "react";
import {
    Container,
    Row,
    Col,
    
} from "reactstrap";
import "./mission.css"
import m3 from "../assets/Values.png"



const Values = () =>{
    return(
        <div className="Mission">
            <Container>
                <Row className="valuesRow" >
                    <Col sm="12" md="12" lg="4" xl="4" className="values">
                   
                    <img className="values-img" src={m3} alt="Mission"/>
                  
                    </Col>
                    <Col sm="12" md="12" lg="8" xl="8" className="missionContent">
                    <h1>Values</h1>
                    <p>
                    At Immigreat, we believe that every stakeholder - both internal and external - deserves to have a genuine and seemless experience resulting in the addition of meaningful value.

                    </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Values;