import Fade from "react-reveal/Fade";
import WhatWeDo from "./WhatWeDo";
import Countries from "../Countries/Countries";
import Steps from "./Steps";
import TryOut from "../TryOut/TryOut";
import axios from "axios";
import { Alert, CONTACT_URL } from "../../helpers/utils";
import ContactForm from "../ContactForm/ContactForm";
import appstore from "./../assets/appstore.png";
import playstore from "./../assets/playstore.png";
import iphone from "./../assets/features/apple-iphone.png";
import macbook from "./../assets/features/macbook-air.png";
import media from "./../assets/features/online-video.png";
import university from "./../assets/features/school.png";
import visa from "./../assets/features/passport.png";
import pr from "./../assets/features/calculator.png";
import costofliv from "./../assets/features/save-money.png";
import oppreport from "./../assets/features/dashboard.png";
import "./style.css";
import { useState } from "react";

const AppFeatures = () => {
    const [activeImg, setActiveImg] = useState(0);

    const handleContactSubmit = async (formData) => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "guest@dashboard",
            },
        };
        await axios
            .post(`${CONTACT_URL}/pr_guidance`, formData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    Alert(
                        "success",
                        "Thank you for contacting us, our team will reach out to you soon!"
                    );
                } else if (res.status === 400) {
                    Alert("error", res.data.status);
                } else {
                    Alert("error", "Some error occured, please try again!");
                }
            })
            .catch((err) => {
                Alert("error", "Some error occured, please try again!");
            });
    };

    const handleClick = () => {
        window.open("https://web.immigreat.global/eligibilityChecker", '_blank', 'noopener,noreferrer');
    }

    const Feature = ({ img, title, description }) => (
        <div style={{ width: "100%", border: "1px solid black", flex: 1, marginBottom: 10, padding: "10px 20px", display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left" }}>
            <img src={img} style={{ height: 55, width: 55 }} />
            <div style={{ fontWeight: "bold", fontSize: 20, margin: "8px 0px" }}>{title}</div>
            <div style={{ fontSize: 14 }}>{description}</div>
        </div>
    )

    const LEFT_FEATURES = [
        { img: media, title: "Media", description: "Get to know the recent updates in the space of immigration through news. stories, and media. Use the filter option to view the information pertaining to your country and intention of interest." },
        { img: visa, title: "Visa Search", description: "Learn about the visas you seek, including their application deadline, duration, and eligibility requirements." },
        { img: university, title: "University Search", description: "Get customized university options based on your specifications and qualifications or Discover more about university possibilities based on your qualifications and preferences." }
    ]

    const RIGHT_FEATURES = [
        { img: pr, title: "PR Points Calculator", description: "Calculate your PR points based on your qualifications for multiple countries in one go." },
        { img: costofliv, title: "Cost of living Calculator", description: "Get to know an approximate cost of living based on your preferences in your desired country." },
        { img: oppreport, title: "Opportunity Report", description: "Get personalised visa suggestions based on your qualifications and requirements." }
    ]

    const ImgTab = () => (
        <>
            <div className="imgtab" style={{ display: "flex", flex: 1 }}>
                <img src={activeImg ? iphone : macbook} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </div>
            <div style={{ display: "flex", marginTop: 40 }}>
                <a onClick={() => setActiveImg(0)} style={{ ...styles.imgBtn, border: activeImg ? "none" : "1px solid #0005", }}>
                    <img src={macbook} style={{ width: 25, height: 20, objectFit: "contain" }} />
                </a>
                <a onClick={() => setActiveImg(1)} style={{ ...styles.imgBtn, border: activeImg ? "1px solid #0005" : "none", }}>
                    <img src={iphone} style={{ width: 20, height: 20, objectFit: "contain" }} />
                </a>
            </div>

        </>
    )

    return (
        <div className="app-features-page">
            <div style={{ fontWeight: "bold", fontSize: 36 }}>One app, many purposes</div>
            <div>Discover immigreat's features and explore how it could help you.</div>
            <div className={"imgtab-mob"}>
                <ImgTab />
            </div>
            <div className="features-container">
                <Fade>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        {LEFT_FEATURES.map((feature, index) => (
                            <Feature {...feature} key={index} />
                        ))}
                    </div>
                </Fade>
                <Fade>
                    <div className={"imgtab-web"}>
                        <ImgTab />
                    </div>
                </Fade>
                <Fade>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        {RIGHT_FEATURES.map((feature, index) => (
                            <Feature {...feature} key={index} />
                        ))}
                    </div>
                </Fade>
            </div>
            <div style={{ margin: "50px 0px" }}>
                <div style={{ fontSize: 25, fontWeight: "bold", }}>Download Immigreat App</div>
                <div style={{ display: "flex" }}>
                    <a href="https://play.google.com/store/apps/details?id=global.immigreat.app&hl=en_CA&gl=US"><img src={appstore} style={{ height: 40, width: 150, margin: 10 }} /></a>
                    <a href="https://apps.apple.com/in/app/immigreat/id1528555782"><img src={playstore} style={{ height: 40, width: 150, margin: 10 }} /></a>
                </div>
                <div>or use to our WebApp, <a style={{ borderBottom: "1px solid black" }} href="https://web.immigreat.global/">click here</a></div>
            </div>
        </div>
    );
};

const styles = {
    imgBtn: {
        padding: "2px 5px",
        margin: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5
    }
}

export default AppFeatures;
