import React from "react";
import { Container, Col } from "reactstrap";
import f1 from "../assets/feature.png";
import f2 from "../assets/secure.png";
import f3 from "../assets/one-stop.png";
import f4 from "../assets/connected.png";
import f5 from "../assets/deepanaly.png";
import f6 from "../assets/value.png";
import f7 from "../assets/efficient.png";
import "./Features.css";

const Features = (props) => {
  return (
    <div className="stack" id="featureSec">
      <Container className="cont">
        <div style={{ color: "white" }}>
          <Col className="stack-col no-wrap" sm="12" md="6" lg="6" xl="4">
            <div className="why-us">Why Us?</div>
          </Col>
          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f1} className="feature" alt="feature" />
                  <h1>SIMPLIFIED</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f2} className="feature" alt="feature" />
                  <h1>SECURE</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f3} className="feature" alt="feature" />
                  <h1 className="one-stop">ONE STOP SOLUTION</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
        <div style={{ color: "white" }}>
          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f4} className="feature" alt="feature" />
                  <h1>PERSONALIZED</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f5} className="feature" alt="feature" />
                  <h1>DEEP ANALYTICS</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f6} className="feature" alt="feature" />
                  <h1>VALUE FOR MONEY</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>

          <Col className="stack-col" sm="12" md="6" lg="6" xl="4">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={f7} className="feature" alt="feature" />
                  <h1>EFFICIENT</h1>
                </div>
                <div class="flip-card-back">
                  <h1>Simplified</h1>
                  <p>lorem ipsum</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default Features;
