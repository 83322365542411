import React from "react";
import {
    Container,
    Row,
    Col,
    
} from "reactstrap";
import "./mission.css"
import m2 from "../assets/Vision.png"



const Vision = () =>{
    return(
        <div className="Mission">
            <Container>
                <Row className="visionRow" >

                    <Col sm="12" md="12" lg="8" xl="8" className="visionContent">
                    <h1>Vision</h1>
                    <p>
                    Our vision is be the world's most reliable immigration solution. We aim to be the most secure and trustworthy source of information for everyone, by leveraging smart technology to propel the world closer toward actualizing their goals. We want to serve our customers to our best and to be prompt in our services.
                    </p>
                    </Col>
                    <Col sm="12" md="12" lg="4" xl="4" className="vison">
                   
                   <img className="vision-img" src={m2} alt="Mission"/>
                 
                   </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Vision;